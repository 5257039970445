@import './variables.css';
@import './text.css';
@import './background.css';
@import './flip_card.css';
@import './typing.css';

@font-face {
  font-family: AktivGrotesk;
  src: url('./fonts/aktiv-grotesk-w01.ttf');
}

* {
  font-family: AktivGrotesk;
}

*::selection {
  color: white;
  background-color: var(--primary);
  transition: transform 0.6s;
}

body {
  margin: unset !important;
  width: 100vw !important;
  max-width: 100vw !important;
  overflow-x: hidden !important;
}

a, .cursor {
  cursor: pointer;
}

input {
  color: var(--darktext) !important;
}

.MuiButtonBase-root, Button {
  font-weight: 700 !important;
}

.default-width {
  max-width: var(--default-width);
}