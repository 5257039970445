:root {
    /* Colors variables */
    --primary: #039BE5;
    --primary-hover: #29aae9;
    --primary-dark: #0384c3;
    --secondary: #FFCA28;
    --secondary-hover: #fecf64;
    --danger: #F06292;
    --danger-hover: #f48fb1;
    --success: #689f38;
    --success-hover: #9ccc65;
    --darktext: #455A64;
    --lighttext: #B0BEC5;
    
    /* System variables */
    --default-width: 1280px;
  }